import React from "react"
import styled from "@emotion/styled"
import * as vars from "../styles/vars"

const HeroAbout = () => {
  return (
    <HeroSection className="hero">
      <div className="container">
        <div className="info">
          <h1>
            Hey! <span>Soy Ainoa</span>.
          </h1>
          <p>
            <b>
              Periodista | Diseñadora gráfica y web | Runner, espartana y
              principio de realfooder
            </b>
          </p>
        </div>
      </div>
    </HeroSection>
  )
}

export default HeroAbout

const HeroSection = styled.section`
  padding-top: 200px;
  position: relative;
  padding-bottom: 118px;
  .container {
    height: auto;
  }
  .info {
    text-align: center;
    width: 100%;
    h1 {
      margin-bottom: 65px;
      ${vars.sm} {
        margin-bottom: 40px;
      }
      ${vars.md} {
        margin-bottom: 40px;
      }
      ${vars.xxl} {
        margin-bottom: 70px;
      }
    }
  }
`
