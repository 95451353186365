import React from "react"
import Layout from "../components/layout"
import HeroAbout from "../components/heroAbout"
import AboutDescription from "../components/aboutDescription"
import SEO from "../components/seo"

const ProyectosPage = () => {
  return (
    <Layout>
      <SEO
        title="Sobre mí - Ainoa Molina"
        description="Esta página habla un poco sobre mis gustos y aficiones"
      />
      <HeroAbout />
      <AboutDescription />
    </Layout>
  )
}

export default ProyectosPage
