import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import * as vars from "../styles/vars"
import { gsap, Power0 } from "gsap"
import { MotionPathPlugin } from "gsap/MotionPathPlugin"
import ainoa from "../images/ainoa.png"
import muss from "../images/muss.png"
import cv from "../static/pdf/Ainoa Molina_cv_2020.pdf"
/* eslint-disable */
gsap.registerPlugin(MotionPathPlugin)

const AboutDescription = () => {
  const texto2 = React.createRef()
  let [texto2Tween, setTexto2Tween] = useState(null)

  useEffect(() => {
    setTexto2Tween(
      gsap.to(texto2.current, {
        duration: 1.5,
        ease: Power0.easeNone,
        paused: true,
        repeat: -1,
        motionPath: {
          path: [
            { left: "8px", top: "0px" },
            { left: "8px", top: "-8px" },
            { left: "-8px", top: "-8px" },
            { left: "-8px", top: "8px" },
            { left: "8px", top: "0px" },
          ],
        },
      })
    )
    // eslint-disable-next-line
  }, [])

  const texto3 = React.createRef()
  let [texto3Tween, setTexto3Tween] = useState(null)

  useEffect(() => {
    setTexto3Tween(
      gsap.to(texto3.current, {
        duration: 1.5,
        ease: Power0.easeNone,
        paused: true,
        repeat: -1,
        motionPath: {
          path: [
            { left: "8px", top: "0px" },
            { left: "8px", top: "-8px" },
            { left: "-8px", top: "-8px" },
            { left: "-8px", top: "8px" },
            { left: "8px", top: "0px" },
          ],
        },
      })
    )
    // eslint-disable-next-line
  }, [])

  const onMouseEnterHandler = () => {
    texto2Tween.play()
  }
  const onMouseLeaveHandler = () => {
    texto2Tween.pause()
  }

  const onMouseEnter = () => {
    texto3Tween.play()
  }
  const onMouseLeave = () => {
    texto3Tween.pause()
  }
  return (
    <Description className="description">
      <div className="container">
        <section className="text">
          <p>
            Bienvenido/a a este espacio donde te cuento un poco más sobre mí.
          </p>
          <p>
            Soy periodista, o al menos eso dice el título universitario que
            guardo con cariño en un cajón lejos del alcance de mi gato, y
            diseñadora gráfica y web. De esto último no tengo ninguna carrera,
            pero sí pasión y algún que otro curso de formación. De echo es lo
            que me da de comer ahora mismo.
          </p>
          <p>
            Trabajo en el Departamento de Comunicación de una escuela privada
            como diseñadora gráfica. El merchandising, las imágenes para redes
            sociales, los documentos informativos, el diseño de infografías y
            los banner son cosa mía. Entre otras muchas cosas.
          </p>
          <p>
            Cuando no estoy trabajando estoy corriendo, ya sea en asfalto o en
            el barro (no solo me apunto a medias maratones, también hago
            carreras de obstáculos). Y para compensar, otro de mis hobbies es la
            cocina, y en especial la repostería, a veces healthy y otras no
            tanto.
          </p>
          <p>
            Esta web es una pequeña muestra de trabajos que he desarrollado
            durante mis años de formación, proyectos propios o ficticios,
            principalmente. Es mi portfolio y un espacio donde subir ideas,
            pruebas o simplemente cosas que me gustan. Además, su diseño ha sido
            mi primera toma de contacto con Sketch.
          </p>
          <p>
            Si tienes cualquier cosa que comentarme o te sobra algún dorsal para
            la maratón de Nueva York, escríbeme.
          </p>
          <p>
            También te invito a pasar por mi Instagram para conocerme mejor
            (@aimmolina) . Puedes ver mis dotes culinarias en mi cuenta
            secundaria (@ainoa_runneat). Y ya que estamos, si prefieres ver
            fotos de mi gato, que ya sabemos que son los reyes de las redes
            sociales, puedes pasarte por su perfil (@muss_chartreux).
          </p>
          <p className="link mobile">
            <a target="_blank" rel="noreferrer" href={cv}>
              DESCARGAR CV
            </a>
          </p>
        </section>
        <section className="media">
          <article className="block">
            <div className="image">
              <img src={ainoa} alt="Ainoa Molina" />
            </div>
            <div className="text">
              <h2
                className="texto3"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                THIS IS ME<span ref={texto3}>THIS IS ME</span>
              </h2>
              <p>
                <b>Esta soy yo.</b>
              </p>
              <p>
                Aquí podéis ponerme cara. En mi defensa diré que no siempre
                estoy tan dormida, pero lo de levantarse a las 6:00 de la mañana
                para salir a correr tiene sus consecuencias, notables sobre todo
                a partir de las 14:00 horas.
              </p>
              <p>
                Si quieres saber mas sobre mí, también puedes echarle un vistazo
                a mi CV.
              </p>
              <p className="link">
                <a target="_blank" rel="noreferrer" href={cv}>
                  DESCARGAR CV
                </a>
              </p>
            </div>
          </article>
          <article className="block">
            <div className="text">
              <h2
                className="texto2"
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
              >
                THIS IS MUSS<span ref={texto2}>THIS IS MUSS</span>
              </h2>
              <p>
                <b>Este es el lindo gatito.</b>
              </p>
              <p>
                Lo de gatito podéis olvidarlo, por su tamaño podríamos decir que
                es toda una pantera. Dueño del sofá, se encarga de despertarme a
                las 6:00 los días que no salgo a correr y de desconfigurarme la
                mesa de trabajo de Photoshop cada vez que se tumba encima del
                ordenador (es decir, todos los días).
              </p>
              <p>
                De mayor quiero ser como él. Y ahora, ahora tambien quiero ser
                como él.
              </p>
            </div>
            <div className="image">
              <img src={muss} alt="Muss" />
            </div>
          </article>
        </section>
      </div>
    </Description>
  )
}

export default AboutDescription

const Description = styled.div`
  padding-bottom: 25px;
  ${vars.sm} {
    padding-bottom: 50px;
  }
  ${vars.lg} {
    padding-bottom: 70px;
  }
  ${vars.xl} {
    padding-bottom: 50px;
  }
  ${vars.xxl} {
    padding-bottom: 150px;
  }
  p {
    font-family: ${vars.barlow};
    font-weight: 300;
    margin-bottom: 40px;
  }
  p.link {
    a {
      font-family: ${vars.barlow};
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
    }
    display: none;
    ${vars.hoverColor}
    ${vars.md} {
      display: block;
    }
  }
  p.link.mobile {
    a {
      font-family: ${vars.barlow};
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer;
    }
    display: block;
    ${vars.hoverColor}
    ${vars.md} {
      display: none;
    }
  }
  section.media {
    display: none;
    ${vars.md} {
      display: block;
    }
  }
  article.block {
    display: grid;
    grid-column-gap: 30px;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 130px;
    img {
      width: 100%;
      height: auto;
    }
    h2 {
      position: relative;
      font-family: ${vars.barlow};
      text-transform: uppercase;
      font-size: 3.063rem;
      display: block;
      z-index: 2;
      color: ${vars.green};
      -webkit-text-fill-color: transparent;
      -webkit-text-stroke-width: 2px;
      -webkit-text-stroke-color: ${vars.green};
      width: 100%;
      line-height: 1.2;
      ${vars.md} {
        font-size: 2.813rem;
        line-height: 1.2;
        margin-bottom: 40px;
      }
      ${vars.lg} {
        font-size: 4.063rem;
      }
      ${vars.xl} {
        font-size: 4.688rem;
      }
      ${vars.xxl} {
        font-size: 5.313rem;
      }
      span {
        position: absolute;
        font-family: ${vars.barlow};
        text-transform: uppercase;
        font-size: 3.063rem;
        display: block;
        z-index: 2;
        color: ${vars.black};
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: ${vars.black};
        top: 0;
        left: 8px;
        width: 100%;
        line-height: 1.2;
        ${vars.md} {
          font-size: 2.813rem;
          line-height: 1.2;
        }
        ${vars.lg} {
          font-size: 4.063rem;
        }
        ${vars.xl} {
          font-size: 4.688rem;
        }
        ${vars.xxl} {
          font-size: 5.313rem;
        }
      }
    }
  }
`
